import type { LinksFunction, MetaFunction } from "@remix-run/node";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { z } from "zod";

import { Button } from "~/components/ui/button";
import { H2, P } from "~/components/ui/typography";

import "./tailwind.css";

export const meta: MetaFunction = () => {
  return [{ title: "kfem" }, { name: "description", content: "shop" }];
};

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export function Layout({ children }: { children: React.ReactNode }) {
  z.setErrorMap((issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === "email") {
        return { message: "Hibás email" };
      }
    }
    return { message: ctx.defaultError };
  });

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/logo.svg" sizes="any" type="image/svg+xml" />
        <link rel="icon" href="/logo.png" type="image/png" />
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (error instanceof Error) {
    switch (error.message) {
      case "no-delete": {
        return (
          <div className="flex flex-col gap-4 h-dvh items-center justify-center">
            <H2>Nem lehet törölni</H2>
            <P className="text-center w-[600px] max-w-[90vw]">
              Ez az elem már hozzá van rendelve más erőforrásokhoz, így nem törölhető. Ha látszólag használaton kívül
              van, akkor vedd fel a kapcsolatot az adminnal, hogy pontosan mi miatt nem lehet törölni.
            </P>
            <Button onClick={() => location.reload()}>Vissza</Button>
          </div>
        );
      }
    }
  }

  return (
    <div className="flex flex-col gap-4 h-dvh items-center justify-center">
      <H2>Valami hiba történt</H2>
      <Button onClick={() => location.reload()}>Próbáljuk újra</Button>
    </div>
  );
}
